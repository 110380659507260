@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Roboto:wght@300;400;700&display=swap"); */

html {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.recharts-text,
.recharts-legend-item-text {
  font-size: 0.8em;
}

/* recharts-cartesian-axis-tick-value */

/* Hide focus outlines */
button {
  outline: none !important;
  box-shadow: none !important;
}

:focus {
  outline: none !important;
}
